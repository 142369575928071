<template>
  <div class="block w-full border-t border-gray-200 dark:border-gray-700">
    <div class="py-4">
      <div class="sm:flex flex-wrap">
        <div class="block w-full">
          <h2 class="text-base mb-3 dark:text-gray-400">Parametra vērtības</h2>
        </div>

        <template v-for="(paramValue, index) in param.param_values" :key="index">
          <Badge :text="paramValue.name" :clickFn="() => deleteParamValue(paramValue.id)" :button="true" class="bg-gray-200 text-gray-800 mr-2 mb-2 md:mb-0" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Badge from "@/components/Components/Badge"

export default {
  name: "CatalogParamValues",
  components: {
    Badge
  },
  props: [ 'param' ],
  data() {
    return {
      showAddForm: false,
      showEditForm: false,
    }
  },
  methods: {
    deleteParamValue(paramValueId){
      if(confirm('Vai esi drošs?')){
        this.$Progress.start()
        this.$store.dispatch('deleteParamValue', paramValueId)
          .then(response => {
            this.$Progress.finish()
          }).catch(error => {
          this.$Progress.fail()
        })
      }
    }
  }
}
</script>

<style>

</style>