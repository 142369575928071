<template>
  <li>
    <item-card contentClass="grid grid-cols-12">
      <template v-slot:title>
        <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300 mb-2 md:mb-0">
          {{ param.display_name }}
        </h3>
      </template>
      <template v-slot:buttons>
        <template v-if="!showEditForm && !showAddForm">

          <Button icon="plus" @click="showAddForm=!showAddForm" />

          <Button icon="pencil" @click="showEditForm=!showEditForm" />

          <Button icon="delete" @click="deleteCategory" />

        </template>
      </template>

      <template v-slot:content>
        <template v-if="!showEditForm">
          <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3">
            <item-text title="Tips" :text="`${param.type ? param.type.display_name : 'Bez tipa'}`" />
          </div>
          <div class="col-span-12 sm:col-span-6">
            <item-text title="Uzskaitāms" :text="`${param.use_readings === 1 ? 'Jā' : 'Nē'}`" />
          </div>
        </template>

      </template>
      <template v-slot:additional>

        <template v-if="showEditForm">
          <EditCatalogParam @close-dialog="showEditForm = false" :param="param" />
        </template>

        <template v-if="showAddForm">
          <AddCatalogParamValue @close-dialog="showAddForm=false" :param="param" />
        </template>

        <template v-if="param.param_values.length > 0">
          <CatalogParamValues :param="param" />
        </template>

      </template>

    </item-card>
  </li>
</template>

<script>
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"
import AddCatalogParamValue from "@/components/Settings/CatalogParam/CatalogParamValue/AddCatalogParamValue"
import CatalogParamValues from "@/components/Settings/CatalogParam/CatalogParamValue/CatalogParamValues"
import EditCatalogParam from "@/components/Settings/CatalogParam/EditCatalogParam"

export default {
  name: "SingleCatalogParam",
  components: {
    ItemCard,
    ItemText,
    AddCatalogParamValue,
    CatalogParamValues,
    EditCatalogParam,
  },
  props: {
    param: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      showAddForm: false,
      showEditForm: false,
    }
  },
  methods: {
    deleteCategory(){
      if(confirm('Vai esi drošs?')){
        this.$Progress.start()
        this.$store.dispatch('deleteParam', this.param.id)
          .then(response => {
            this.$Progress.finish()
          }).catch(error => {
          this.$Progress.fail()
        })
      }
    }
  }
}
</script>
