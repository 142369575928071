<template>
  <form v-if="form && inputData" class="space-y-6 w-full">

    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div class="col-span-1">
        <Input v-model="form.name" :errors="errors.name" placeholder="Nosaukums" />
      </div>

      <div class="col-span-1">
        <Select v-model="form.type" :items="inputData.types" :errors="errors.type" :name="'display_name'" placeholder="Pielietojums" />
      </div>

      <div class="col-span-1 flex items-center">
        <Checkbox text="Uzskaitāms" v-model="form.use_readings" />
      </div>
    </div>

    <div class="pb-3">
      <div class="flex justify-end">
        <button type="button" class="bg-white dark:bg-gray-750 py-2 px-4 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 mr-2" @click="close">
          Atcelt
        </button>

        <template v-if="!loading">
          <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
            Saglabāt
          </button>
        </template>
        <template v-else>
          <Loading />
        </template>
      </div>
    </div>
  </form>
</template>

<script>
import Input from "@/components/Components/Input";
import Select from "@/components/Components/Select";
import Checkbox from "@/components/Components/Checkbox";
import { mapGetters } from "vuex";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";

export default {
  name: "EditCatalogParam",
  components: {
    Input,
    Select,
    Checkbox,
    Loading
  },
  props: ["param"],
  data: () => ({
    form: null,
  }),
  created() {
    this.$store.dispatch("getParamInputData");
  },
  mounted() {
    this.form = this.param
    this.form.use_readings = Boolean(this.form.use_readings)
  },
  computed: {
    ...mapGetters({
      inputData: 'paramInputData',
      loading: "loading",
      errors: "errors",
    }),
    formValidation() {
      return {
        name: {
          rules: ['required']
        },
      }
    }
  },
  methods: {
    close() {
      this.$emit('close-dialog')
      this.$store.dispatch("setErrors", {})
    },
    submit() {
      this.$Progress.start();

      if (Validation(this.formValidation, this.form)) {
        this.$store.dispatch('updateParam', {
            id : this.param.id,
            name : this.form.name,
            param_type_id: this.form.type ? this.form.type.id : null,
            use_readings: this.form.use_readings,
          })
          .then(response => {
            this.$Progress.finish()
            this.$emit('close-dialog')
          })
          .catch(error => {
            this.errors = error.response.data.errors.name
            this.$Progress.fail()
          })
      } else {
        this.$Progress.fail();
      }
    },
  },
}
</script>