<template>
  <form class="space-y-6 w-full">

    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div class="col-span-1">
        <Input v-model="form.name" :errors="errors.name" placeholder="Parametra vērtības nosaukums" />
      </div>
    </div>

    <div class="pb-3">
      <div class="flex justify-end">
        <button type="button" class="bg-white dark:bg-gray-750 py-2 px-4 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 mr-2" @click="close">
          Atcelt
        </button>

        <template v-if="!loading">
          <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
            Saglabāt
          </button>
        </template>
        <template v-else>
          <Loading />
        </template>
      </div>
    </div>
  </form>
</template>

<script>
import Input from "@/components/Components/Input";
import { mapGetters } from "vuex";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";

export default {
  name: "AddCatalogParamValue",
  components: {
    Input,
    Loading
  },
  props: ["param"],
  data: () => ({
    form: {
      name: '',
    }
  }),
  computed: {
    ...mapGetters({
      loading: "loading",
      errors: "errors",
    }),
    formValidation() {
      return {
        name: {
          rules: ['required']
        },
      }
    }
  },
  methods: {
    close() {
      this.$emit('close-dialog')
      this.$store.dispatch("setErrors", {})
    },
    submit() {
      this.$Progress.start();

      if (Validation(this.formValidation, this.form)) {
        this.$store.dispatch('createNewParamValue', {
          name: this.form.name,
          catalog_param_id: this.param.id
        })
        .then(response => {
          this.$Progress.finish()
          this.$emit('close-dialog')
        })
        .catch(error => {
          this.$Progress.fail()
          this.errors = error.response.data.errors
        })
      } else {
        this.$Progress.fail();
      }
    },
  },
}
</script>